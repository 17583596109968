
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { KhoService } from "@/core/services/Kho.service";
import {
  ChenhLechVatTuBienBanResponse,
  IBienBanGiaiTheResponseBase,
  IKhoResponseBase,
  IVatTuDinhMucCoDinh,
  IVatTuNgoaiDinhMuc,
  IVatTuTrongKhoResponseBase,
  IVatTuXuatKhoResponseBase,
  ReceiptBaseResponse,
} from "@/core/interfaces/ApiResponses";
import { IAccountSelectedBase, IVatTuSelectedInKho, } from "@/core/interfaces/Models";
import { formatCurrencyAndQuantity, swalErrNotification, swalNotification } from "@/core/helpers/utils";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { BienBanGiaiTheService } from '@/core/services/BienBanGiaiThe.service';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { DinhMucCoDinhService } from '@/core/services/DinhMucCoDinh.service';
import { useLoading } from '@/core/hooks/use-loading';
import { VatTuService } from '@/core/services/VatTu.service';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { UserService } from '@/core/services/UserService';
import { LOAI_DINH_MUC } from '@/core/constants/const';
import { useEnv } from "@/core/hooks/useEnv";
import { useDateTime } from "@/core/hooks/use-date-time-hook";

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import moment from 'moment';
import SuppliesTable from '@/views/crafted/pages/receipts/supplies-table/SuppliesTable.vue';
import SupplyGettingDetail from '@/views/crafted/pages/receipts/supply-getting-detail/SupplyGettingDetail.vue';
import SuppliesFromWarehouses from '@/views/crafted/pages/receipts/supplies-from-warehouses/SuppliesFromWarehouses.vue';
import PhieuXuatKhoService from '@/core/services/Receipt.service';
import ConfirmationDialog from "@/components/confirmation-dialog/ConfirmationDialog.vue";
import ConfirmHoanThanh
  from "@/views/crafted/pages/bien-ban-giai-the/confirm-hoan-thanh-bien-ban-modal/ConfirmHoanThanh.vue";
import ToolService from "@/core/services/Tool.service";
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import PhieuXuatKhoPrintModal
  from '@/views/crafted/pages/receipts/phieu-xuat-kho-print-modal/PhieuXuatKhoPrintModal.vue'
import usePhieuXuat from '@/core/hooks/usePhieuXuat';
import useTaiKhoan from '@/core/hooks/useTaiKhoan';
import TaiKhoanSelectionV2 from '@/components/shared/tai-khoan-selection/TaiKhoanSelectionV2.vue';
import ResponsiveLayout from '@/layout/_shared/ResponsiveLayout.vue';

export default defineComponent({
  name: 'phieu-xuat-kho-form',

  components: {
    ConfirmationDialog,
    ConfirmHoanThanh,
    ButtonCustom,
    PageRowLayout,
    PhieuXuatKhoPrintModal,
    ResponsiveLayout,
    SuppliesTable,
    SupplyGettingDetail,
    SuppliesFromWarehouses,
    TaiKhoanSelectionV2,
  },

  setup() {
    const route = useRoute();

    const router = useRouter();

    const { setTkData, tkData } = useTaiKhoan();

    const { id: idBienBan, goBack } = useRouterCustom('idBienBan');

    const { id: idPhieuXuat } = useRouterCustom('idPhieuXuatKho');

    const { startLoading, endLoading } = useLoading();

    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    const { showHoanThanhPhieuXuat } = useEnv();

    const { formatDisplayingDate } = useDateTime();

    const { allowEditingTaiKhoan, goToEditPhieuXuatLink, isPhieuXuatEditable } = usePhieuXuat();

    const phieuXuatKhoPrintModalRef = ref();

    // form elements
    const loading = ref<boolean>(false);

    const formRef = ref<null | HTMLFormElement>(null);

    // local state
    const isCreatingFormBierBan = ref(false);

    const isCreating = ref(true);

    const isPhieuXuatDetailPage = ref(false);

    const so_phieu = ref('');

    const vatTuSelected = ref<IVatTuXuatKhoResponseBase[]>([]);

    const nguoiDungItems = ref<any[]>([])

    const debitAccount = ref<IAccountSelectedBase>({
      id: -1,
      ma_tai_khoan: "",
      ma_chi_tiet: "",
    });

    const creditAccount = ref<IAccountSelectedBase>({
      id: -1,
      ma_tai_khoan: "",
      ma_chi_tiet: "",
    });

    const phieuXuatDetail = ref<ReceiptBaseResponse>({
      count_not_in_dmd: 0,
      hoan_thanh: false,
      id: -1,
      id_bien_ban_giai_the: -1,
      id_bo_phan: -1,
      id_don_vi: -1, // thêm vào cho đủ, ko dùng đến
      id_kho: -1, // thêm vào cho đủ, ko dùng đến
      id_nguoi_dung: -1, // thêm vào cho đủ, ko dùng đến
      id_nguoi_nhan: -1,
      is_approved: false,
      so_bien_ban: '', // thêm vào cho đủ, ko dùng đến
      so_phieu: '', // thêm vào cho đủ, ko dùng đến
      created_at: '', // thêm vào cho đủ, ko dùng đến
      updated_at: '', // thêm vào cho đủ, ko dùng đến
      nam: 0, // thêm vào cho đủ, ko dùng đến
      bien_ban_giai_the: null,
      kho: null,
      ngay: null,
      nguoi_nhan: null,
      vat_tu_xuat_kho: [],
      ly_do: '',
      ngay_hoan_thanh: null,
      total_export_amount: 0, // thêm vào cho đủ, ko dùng đến
      tk_no: {
        ma_tk: '',
        ten_tk: '',
        ma_ct: '',
      },
    });

    const editable = computed(() => isPhieuXuatEditable(phieuXuatDetail.value));

    const editableTaiKhoan = computed(() => allowEditingTaiKhoan(phieuXuatDetail.value));

    // form items
    const form = reactive({
      so_phieu: "",
      id_nguoi_nhan: -1,
      receiptReason: "",
      so_bien_ban: "",
      firstWarehouseId: -1,
      receiptDate: "",
    });

    const rules = reactive({
      id_nguoi_nhan: [
        {
          required: true,
          message: "Phải nhập tên người nhận hàng",
          trigger: "blur",
        },

        {
          type: 'number',
          message: "Phải nhập tên người nhận hàng",
          min : 1,
        }
      ],
      receiptReason: [
        {
          required: true,
          message: "Phải nhập lý do xuất kho",
          trigger: "blur",
        },
      ],
      so_bien_ban: [
        {
          required: true,
          message: "Phải nhập số biên bản",
          trigger: "blur",
        },
      ],
    });
    // ============================

    const bienBanGiaiTheDetail = ref<IBienBanGiaiTheResponseBase | null>(null);

    const vatTuSelectedInKho = ref<IVatTuSelectedInKho[]>([]);

    const selectingKhoItem = ref<IKhoResponseBase | null>(null);

    const khoItems = ref<IKhoResponseBase[]>([]);

    const totalPriceInText = ref("");

    // For detail supply dialog
    const detailSupplyIndex = ref(-1);

    const detailSupplyData = ref<{
      ten_vat_tu: string;
      don_gia: number;
      don_vi_tinh: string;
    }>({
      ten_vat_tu: "",
      don_gia: 0,
      don_vi_tinh: "",
    });

    const detailGettingSupplyData = ref<IVatTuSelectedInKho>({
      id_vat_tu: -1,
      so_luong_can: -1,
      con_thieu: -1,
      kho_arr: [],
    });

    const selectedWarehouses = ref<
      {
        ten_kho: string;
        tong_tien: number;
        tong_tien_bang_chu: string;
        vat_tu_da_lay: {
          ten_vat_tu: string;
          so_luong_lay: number;
          don_gia: number;
          con_lai: number;
          don_vi_tinh: string;
        }[];
      }[]
    >([]);

    onMounted(async () => {
      try {
        isCreating.value = route.name === 'tao-moi-phieu-xuat-kho';

        isCreatingFormBierBan.value = route.path.includes('bien-ban-giai-the') && +idBienBan.value !== 0;

        if (
          route?.name === 'chi-tiet-phieu-xuat-kho' ||
          route?.name === 'chi-tiet-phieu-xuat-kho-khac'
        ) {
          isPhieuXuatDetailPage.value = true;
        }

        await startLoading();

        if (isPhieuXuatDetailPage.value || !isCreating.value) {
          const {
            data: { data: response },
          } = await PhieuXuatKhoService.get(idPhieuXuat.value);

          phieuXuatDetail.value = { ...response };

          form.so_phieu = phieuXuatDetail.value.so_phieu;

          form.receiptReason = phieuXuatDetail.value.ly_do;

          if (phieuXuatDetail.value.id_bien_ban_giai_the) {
            isCreatingFormBierBan.value = true;

            idBienBan.value = phieuXuatDetail.value.id_bien_ban_giai_the;
          }
        }

        await endLoading();
      } catch (e) {
        await endLoading();

        await swalErrNotification(e, 'Có lỗi khi lấy chi tiết thông tin phiếu xuất');
      }
    });

    const fetchNguoiDung = async () => {
      const { data: { data: { data } } } = await UserService.list();

      nguoiDungItems.value = data;
    }

    const resetSelectingWarehouse = () => {
      selectingKhoItem.value = null;
    };

    const removeSelectingWarehouse = (warehouseId: number) => {
      khoItems.value = khoItems.value.filter(
        (warehouse) => warehouse.id !== warehouseId
      );
      form.firstWarehouseId = -1;
    };

    const onSelectedCreditAccountSuccessHandler = ({ id }: { id: number }) => {
      creditAccount.value.id = id;
    };

    const onSelectedDebitAccountSuccessHandler = ({ id }: { id: number }) => {
      debitAccount.value.id = id;
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          try {
            await startLoading();

            await PhieuXuatKhoService.createFromBBGT(
              +idBienBan.value,
              form.so_bien_ban,
              form.id_nguoi_nhan,
              vatTuSelectedInKho.value
            );

            await endLoading();

            await swalNotification('Tạo phiếu xuất thành công');

            await router.push(
              `/quan-ly/bien-ban-giai-the/chi-tiet/${idBienBan.value}`,
            );
          } catch (err) {
            await endLoading();

            await swalErrNotification(
              err,
              'Xin lỗi, có lỗi xảy khi tạo phiếu xuất kho, mời bạn thực hiện lại',
            );
          }
        } else {
          return false;
        }
      });
    };

    return {
      ButtonsType, ButtonTypeColors, goBack,
      bienBanGiaiTheDetail,
      detailSupplyIndex,
      detailSupplyData,
      detailGettingSupplyData,
      editable, editableTaiKhoan,
      goToEditPhieuXuatLink,
      idBienBan,
      form,
      formatDisplayingDate,
      loading,
      formRef,
      idPhieuXuat,
      isCreatingFormBierBan,
      phieuXuatKhoPrintModalRef,
      isCreating,
      isPhieuXuatDetailPage,
      showHoanThanhPhieuXuat,
      so_phieu,
      fetchNguoiDung,
      khoItems,
      vatTuSelected,
      vatTuSelectedInKho,
      selectingKhoItem,
      totalPriceInText,
      rules,
      selectedWarehouses,
      phieuXuatDetail,
      debitAccount,
      creditAccount,
      nguoiDungItems,
      resetSelectingWarehouse,
      removeSelectingWarehouse,
      onSelectedCreditAccountSuccessHandler,
      onSelectedDebitAccountSuccessHandler,
      onSubmit,
      startLoading, endLoading,
    };
  },

  data() {
    return {
      isPhieuLe: false,
      loaiDinhMuc: LOAI_DINH_MUC.TAT_CA_DINH_MUC as LOAI_DINH_MUC,
      showHoanThanhPhieuXuatModal: false,
      showConfirmDeleteModal: false,
      vatTuDmcdItems: [] as IVatTuDinhMucCoDinh[],
    }
  },

  async mounted() {
    switch (this.$route.query['loai-dinh-muc']) {
      case LOAI_DINH_MUC.DINH_MUC_CO_DINH:
        this.loaiDinhMuc = LOAI_DINH_MUC.DINH_MUC_CO_DINH;
        break;
      case LOAI_DINH_MUC.NGOAI_DINH_MUC:
        this.loaiDinhMuc = LOAI_DINH_MUC.NGOAI_DINH_MUC;
        break;
      default:
        this.loaiDinhMuc = LOAI_DINH_MUC.TAT_CA_DINH_MUC;
    }

    this.isPhieuLe = this.$route.query['phieu-xuat-le'] === 'true';

    await this.startLoading();

    try {
      if (this.isCreatingFormBierBan || !this.isCreating) {
        setCurrentPageTitle('Phiếu xuất kho');

        if (this.isCreatingFormBierBan) {
          await this.fetchBienBanGiaiThe();
        }

        await this.fetchKhoItems();

        await this.fetchVatTuDinhMucCoDinh();

        await this.fetchNguoiDung();

        if (this.bienBanGiaiTheDetail) {
          this.form.receiptReason = `Phiếu xuất kho cho toa xe ${this.bienBanGiaiTheDetail.toa_xe.so_hieu} - ${this.bienBanGiaiTheDetail.ke_hoach_sua_chua?.cap_sua_chua?.ten_cap_sc || this.bienBanGiaiTheDetail.cap_sua_chua.ten_cap_sc}`;

          this.form.so_bien_ban = this.bienBanGiaiTheDetail.so_bien_ban;

          this.form.so_phieu = this.phieuXuatDetail.so_phieu;
        }

        if (this.isPhieuXuatDetailPage) {
          const {
            data: { data: response },
          } = await PhieuXuatKhoService.get(this.idPhieuXuat);

          this.phieuXuatDetail = response;

          this.form.so_bien_ban = this.phieuXuatDetail?.so_bien_ban;

          this.form.receiptReason = this.phieuXuatDetail?.ly_do;

          this.form.id_nguoi_nhan = this.phieuXuatDetail.nguoi_nhan?.ho_va_ten
            ? this.phieuXuatDetail.nguoi_nhan.id
            : 0;

          this.form.receiptDate = moment(this.phieuXuatDetail.created_at).format(
            'DD-MM-YYYY'
          );

          this.form.firstWarehouseId = this.phieuXuatDetail.kho?.id
            ? this.phieuXuatDetail.kho.id
            : -1;

          this.selectedWarehouses.push({
            ten_kho: this.phieuXuatDetail.kho?.ten_kho
              ? this.phieuXuatDetail.kho.ten_kho
              : '',
            tong_tien: 0,
            tong_tien_bang_chu: '',
            vat_tu_da_lay: [],
          });

          const lastIndex = this.selectedWarehouses.length - 1;

          let totalPriceOfWarehouse = 0;

          let vat_tu_trong_kho: IVatTuTrongKhoResponseBase[] = [];

          if (this.phieuXuatDetail.kho?.id) {
            const {
              data: {
                data: { data },
              },
            } = await VatTuService.getVatTuInKho({
              idKho: this.phieuXuatDetail.kho?.id,
              vat_tu_ids: this.vatTuSelected.map(({ id }) => id),
            });

            vat_tu_trong_kho = data;
          }

          this.phieuXuatDetail.vat_tu_xuat_kho.forEach((vat_tu) => {
            const so_luong_con_lai = vat_tu_trong_kho.find(
              (v) => v.id === vat_tu.id
            )?.pivot.so_luong;

            this.selectedWarehouses[lastIndex].vat_tu_da_lay.push({
              ten_vat_tu: vat_tu.ten_vat_tu,
              so_luong_lay: +vat_tu.pivot.so_luong,
              don_gia: +vat_tu.pivot.don_gia,
              con_lai: so_luong_con_lai ? +so_luong_con_lai : 0,
              don_vi_tinh: vat_tu.don_vi_tinh,
            });

            totalPriceOfWarehouse += +vat_tu.pivot.so_luong * +vat_tu.pivot.don_gia;
          });

          const {
            data: { data: totalPriceOfWarehouseInText },
          } = await ToolService.convertPriceToText(Math.round(totalPriceOfWarehouse));

          this.selectedWarehouses[lastIndex].tong_tien = Math.round(totalPriceOfWarehouse);

          this.selectedWarehouses[lastIndex].tong_tien_bang_chu =
            totalPriceOfWarehouseInText;
        }

        let vatTuChenhLechArr: ChenhLechVatTuBienBanResponse[] = [];

        if (this.loaiDinhMuc === LOAI_DINH_MUC.NGOAI_DINH_MUC) {
          const {
            data: {
              data: vatTuChenhLechResponse,
            },
          } = await PhieuXuatKhoService.vaildateVatTuXuatKho({
            id: this.idBienBan,
            ...(!this.isCreating && !this.isPhieuXuatDetailPage && {
              exclude_id_phieu_xuat_items: [this.phieuXuatDetail.id],
            }),
          });

          vatTuChenhLechArr = vatTuChenhLechResponse;
        }

        this.vatTuSelected = this.vatTuItemsTransform({
          vatTuDmcdItems: this.vatTuDmcdItems,
          vatTuNgoaiDmItems: this.bienBanGiaiTheDetail?.ngoai_dinh_muc ? this.bienBanGiaiTheDetail?.ngoai_dinh_muc : [],
          vatTuNdmChenhLechItems: vatTuChenhLechArr,
        });

        await this.calcThanhTien();

        this.resetSelectedVatTuInKho();

        await this.endLoading();
      }
    } catch (e) {
      await this.endLoading();

      await swalErrNotification(e, 'Có lỗi xảy ra');
    }
  },

  computed: {
    isGettingAllSupplies(): boolean {
      if (this.isPhieuLe) {
        let foundItem = this.vatTuSelectedInKho.findIndex(({ kho_arr }) => kho_arr.length > 0);

        return foundItem >= 0;
      }

      let foundItem = this.vatTuSelectedInKho.findIndex((item) => {
        return item.con_thieu > 0;
      });

      return foundItem === -1;
    },
    isPhieuXuatKhongTheoBienBan() {
      return +this.idBienBan === 0;
    },
    selectingTenKho(): string {
      if (this.selectedWarehouses[0]?.ten_kho) {
        return this.selectedWarehouses[0].ten_kho;
      }

      return '';
    },

    titleComputed(): string {
      if (this.isCreating) {
        return `Tạo mới ${
          this.loaiDinhMuc === LOAI_DINH_MUC.DINH_MUC_CO_DINH ? '(ĐMCĐ)'
            : this.loaiDinhMuc === LOAI_DINH_MUC.NGOAI_DINH_MUC ? '(Ngoài Định Mức)'
              : ''
        }`.trim();
      }

      if (this.isPhieuXuatDetailPage) return 'Chi tiết';

      return 'Chỉnh sửa';
    },

    totalSuppliesPrice() {
      let totalPrice = 0;
      this.vatTuSelectedInKho.forEach((item, index) => {
        totalPrice += item.so_luong_can * this.vatTuSelected[index].don_gia;
      });
      return formatCurrencyAndQuantity(totalPrice, false);
    },
  },

  methods: {
    async fetchBienBanGiaiThe(): Promise<void> {
      if (this.isPhieuXuatKhongTheoBienBan) {
        return;
      }

      try {
        const {
          data: { data: bienBanData },
        } = await BienBanGiaiTheService.get(this.idBienBan);

        this.bienBanGiaiTheDetail = bienBanData;
      } catch (e) {
        await swalErrNotification(e, 'Có lỗi khi lấy thông tin chi tiết của BBGT');
      }
    },

    async fetchKhoItems() {
      await this.startLoading();

      try {
        const {
          data: {
            data: {
              data,
            },
          },
        } = await KhoService.fetchAllWarehouses();

        this.khoItems = data;
      } catch (e) {
        await this.endLoading();

        await swalErrNotification(e, 'Có lỗi khi lấy danh sách kho')
      }
    },

    async fetchVatTuInKho(khoId: number): Promise<IVatTuTrongKhoResponseBase[]> {
      const { data: { data: { data } } } = await VatTuService.getVatTuInKho({
        idKho: khoId,
        vat_tu_ids: this.vatTuSelected.map(({ id }) => id),
      });

      return data;
    },

    async onWarehouseChangedHandler(id: number) {
      await this.startLoading();

      try {
        const {
          data: { data },
        } = await KhoService.get(id);

        data.vat_tu = await this.fetchVatTuInKho(id);

        this.selectingKhoItem = { ...data };

        await this.endLoading();
      } catch(e) {
        await this.endLoading();

        await swalErrNotification(e, 'Xin lỗi, có lỗi xảy khi lấy thông tin kho, mời bạn thực hiện lại');
      }
    },

    async fetchVatTuDinhMucCoDinh() {
      if (!this.bienBanGiaiTheDetail) return;

      try {
        const dinhMucResponse = await DinhMucCoDinhService.getDinhMucCoDinhByIdCapSuaChuaAndDMTX({
          id_cap_sc: this.bienBanGiaiTheDetail?.ke_hoach_sua_chua?.cap_sua_chua?.id
            || this.bienBanGiaiTheDetail?.cap_sua_chua.id,
          id_dm_toa_xe: this.bienBanGiaiTheDetail?.ke_hoach_sua_chua?.id_danh_muc_toa_xe
            || this.bienBanGiaiTheDetail?.toa_xe.danh_muc_toa_xe[0].id,
        });

        if (dinhMucResponse?.vat_tu) {
          this.vatTuDmcdItems = dinhMucResponse.vat_tu;
        }
      } catch (e) {
        await this.endLoading();

        await swalErrNotification(e, 'Có lỗi xảy ra khi lấy thông tin ĐMCĐ');
      }
    },

    vatTuItemsTransform({
      vatTuDmcdItems,
      vatTuNgoaiDmItems,
      vatTuNdmChenhLechItems,
    }: {
      vatTuDmcdItems: IVatTuDinhMucCoDinh[],
      vatTuNgoaiDmItems: IVatTuNgoaiDinhMuc[],
      vatTuNdmChenhLechItems: ChenhLechVatTuBienBanResponse[],
    }): IVatTuXuatKhoResponseBase[] {
      let result = [] as IVatTuXuatKhoResponseBase[];

      switch (this.loaiDinhMuc) {
        case LOAI_DINH_MUC.DINH_MUC_CO_DINH:
          result = vatTuDmcdItems.map(vatTu => ({
            ...vatTu,
            pivot: {
              id_kho: 0,
              id_vat_tu: vatTu.id,
              so_luong: vatTu.pivot.so_luong,
              id_phieu_xuat_kho: 0,
            }
          }));

          break;
        case LOAI_DINH_MUC.NGOAI_DINH_MUC:
          result = vatTuNgoaiDmItems
            .filter(vatTu => {
              const vatTuChenhLech = vatTuNdmChenhLechItems.find(
                ({ id_vat_tu: idVatTuChenhLech }) => idVatTuChenhLech === vatTu.id,
              );

              if (!vatTuChenhLech) return false;

              return  vatTu.pivot.is_approved === 1 && +vatTuChenhLech.chenh_lech > 0;
            })
            .map(vatTu => {
              const vatTuChenhLech = vatTuNdmChenhLechItems.find(
                ({ id_vat_tu: idVatTuChenhLech }) => idVatTuChenhLech === vatTu.id,
              );

              return {
                ...vatTu,
                pivot: {
                  id_kho: 0,
                  id_vat_tu: vatTu.id,
                  so_luong: vatTuChenhLech?.chenh_lech ?? vatTu.pivot.so_luong,
                  id_phieu_xuat_kho: 0,
                }
              }
            });

          break;
        default:
          vatTuDmcdItems.forEach(vatTuInDmcd => {
            let newQuantity = vatTuInDmcd.pivot.so_luong;

            for (let i = 0; i < vatTuNgoaiDmItems.length; i++) {
              if (
                vatTuNgoaiDmItems[i].id === vatTuInDmcd.id
                && vatTuNgoaiDmItems[i].pivot.is_approved === 1
              ) {
                newQuantity += vatTuNgoaiDmItems[i].pivot.so_luong;
                break;
              }
            }

            result.push({
              ...vatTuInDmcd,
              pivot: {
                id_kho: 0,
                id_vat_tu: vatTuInDmcd.id,
                so_luong: newQuantity,
                id_phieu_xuat_kho: 0,
              }
            });
          });
      }

      return result;
    },

    resetSelectedVatTuInKho() {
      this.vatTuSelectedInKho = [];

      this.vatTuSelected.forEach(item => {
        this.vatTuSelectedInKho.push({
          id_vat_tu: item.id,
          so_luong_can: item.pivot.so_luong,
          con_thieu: item.pivot.so_luong,
          kho_arr: [],
        });
      });
    },

    detailButtonClickedHandler(supplyId: number, index: number) {
      this.detailSupplyIndex = index;
      this.detailSupplyData = {
        ten_vat_tu: this.vatTuSelected[index].ten_vat_tu,
        don_gia: this.vatTuSelected[index].don_gia,
        don_vi_tinh: this.vatTuSelected[index].don_vi_tinh,
      };
      this.detailGettingSupplyData = this.vatTuSelectedInKho[index];
    },

    async calcThanhTien() {
      let totalPrice = 0;
        this.vatTuSelectedInKho.forEach((item, index) => {
          totalPrice +=
            item.so_luong_can * this.vatTuSelected[index].don_gia;
        });

        const {
          data: { data },
        } = await ToolService.convertPriceToText(totalPrice);

        this.totalPriceInText = data;
    },

    async onNextButtonClickedHandler(
      quantityData: {
        id_vat_tu: number;
        so_luong_trong_kho_ban_dau: number;
        so_luong_se_lay: number;
        don_gia_trong_kho: number;
      }[]
    ) {
      this.selectedWarehouses.push({
        ten_kho: this.selectingKhoItem?.ten_kho ? this.selectingKhoItem.ten_kho : '',
        tong_tien: 0,
        tong_tien_bang_chu: "",
        vat_tu_da_lay: [],
      });

      const vat_tu_se_lay: {
        ten_vat_tu: string;
        so_luong_lay: number;
        don_gia: number;
        con_lai: number;
        don_vi_tinh: string;
      }[] = [];

      this.vatTuSelectedInKho.forEach((vat_tu_dang_can) => {
        const index = quantityData.findIndex(
          ({ id_vat_tu }) => id_vat_tu === vat_tu_dang_can.id_vat_tu
        );

        const vat_tu_trong_bien_ban = this.vatTuSelected.find(({ id }) => id === vat_tu_dang_can.id_vat_tu);

        if (index >= 0 && quantityData[index].so_luong_se_lay && +quantityData[index].so_luong_se_lay > 0) {
          vat_tu_se_lay.push({
            ten_vat_tu: vat_tu_trong_bien_ban.ten_vat_tu,
            so_luong_lay: +quantityData[index].so_luong_se_lay,
            don_gia: quantityData[index].don_gia_trong_kho,
            con_lai:
              quantityData[index].so_luong_trong_kho_ban_dau -
              quantityData[index].so_luong_se_lay,
            don_vi_tinh: vat_tu_trong_bien_ban.don_vi_tinh,
          });

          this.selectedWarehouses[
            this.selectedWarehouses.length - 1
          ].tong_tien +=
            quantityData[index].so_luong_se_lay *
            +quantityData[index].don_gia_trong_kho;

          vat_tu_dang_can.kho_arr.push({
            kho_id: this.selectingKhoItem?.id || 0,
            ten_kho: this.selectingKhoItem?.ten_kho || '',
            tong_so_trong_kho: quantityData[index].so_luong_trong_kho_ban_dau,
            da_lay: +quantityData[index].so_luong_se_lay,
            tk_ghi_no: this.debitAccount.id,
            tk_ghi_co: this.creditAccount.id,
            con_lai:
              quantityData[index].so_luong_trong_kho_ban_dau -
              quantityData[index].so_luong_se_lay,
          });

          vat_tu_dang_can.con_thieu =
            vat_tu_dang_can.con_thieu - quantityData[index].so_luong_se_lay;
        }
      });

      this.selectedWarehouses[
        this.selectedWarehouses.length - 1
      ].vat_tu_da_lay = [...vat_tu_se_lay];

      if (
        this.selectedWarehouses[this.selectedWarehouses.length - 1].tong_tien >
        0
      ) {
        const {
          data: { data },
        } = await ToolService.convertPriceToText(
          this.selectedWarehouses[this.selectedWarehouses.length - 1].tong_tien
        );

        this.selectedWarehouses[
          this.selectedWarehouses.length - 1
        ].tong_tien_bang_chu = data;
      }

      if (
        this.selectedWarehouses[this.selectedWarehouses.length - 1]
          .tong_tien === 0
      ) {
        this.selectedWarehouses.pop();
      }

      if (this.selectingKhoItem) {
        this.removeSelectingWarehouse(this.selectingKhoItem.id);
      }

      if (this.isPhieuLe) {
        // TODO (ross): update here
      }

      this.resetSelectingWarehouse();
    },

    onClearQuantityDataHandler() {
      this.selectedWarehouses = [];

      this.resetSelectedVatTuInKho();

      this.fetchKhoItems();
    },

    resetSupplyViewDetail() {
      this.detailSupplyIndex = -1;
      this.detailSupplyData = {
        ten_vat_tu: '',
        don_gia: 0,
        don_vi_tinh: '',
      };
      this.detailGettingSupplyData = {
        id_vat_tu: -1,
        so_luong_can: 0,
        con_thieu: 0,
        kho_arr: [],
      };
    },

    confirmHoanThanh({ date }: { date: Date }) {
      this.phieuXuatDetail.ngay_hoan_thanh = date;

      this.showHoanThanhPhieuXuatModal = false;
    },

    async confirmDelete() {
      await PhieuXuatKhoService.delete(this.phieuXuatDetail.id);

      await swalNotification('Xoá phiếu xuất thành công');

      this.goBack();
    }
  },
});
